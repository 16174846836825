.slide-confirm-slider {
  line-height: 0;
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: var(--ui-button-border-radius);
}
.slide-confirm-slider .slide-message {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  line-height: 1;
  color: #fff;
  pointer-events: none;
  font-size: 17px;
}
.slide-confirm-slider .slide-progress {
  left: 0%;
  width: 100%;
  background: #ff0000;
  height: 100%;
  position: absolute;
  transform: translate(calc(-100% + 20px), 0);
  pointer-events: none;
}
.slide-confirm-slider .pullee {
  width: 100%;
  appearance: none;
  pointer-events: none;
  height: 44px;
}
.slide-confirm-slider .pullee:active::-webkit-slider-thumb {
  appearance: none;
  cursor: grabbing;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
}
.slide-confirm-slider .pullee:active::-moz-range-thumb {
  border: 0;
  cursor: grabbing;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
}
.slide-confirm-slider .pullee:active::-ms-thumb {
  cursor: grabbing;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
}
.slide-confirm-slider .pullee:focus {
  outline: none;
}
.slide-confirm-slider .pullee::-webkit-slider-thumb {
  appearance: none;
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #fff;
  transform-origin: 50% 50%;
  transform: scale(1);
  transition: transform ease-out 100ms;
  cursor: grab;
  cursor: -webkit-grab;
  background-image: url("data:image/svg+xml,%3Csvg width='21' height='19' viewBox='0 0 21 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.8438 1.9375L19.3438 9.4375L11.8438 16.9375' stroke='%23FF0000' stroke-width='2.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M19.3437 9.4375L1.40625 9.4375' stroke='%23FF0000' stroke-width='2.5' stroke-linecap='round'/%3E%3C/svg%3E%0A");
  background-position: 46% 50%;
  background-repeat: no-repeat;
  background-size: 17px 17px;
  pointer-events: auto;
}
.slide-confirm-slider .pullee::-moz-range-thumb {
  border: 0;
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #fff;
  transform-origin: 50% 50%;
  transform: scale(1);
  transition: transform ease-out 100ms;
  cursor: grab;
  cursor: -webkit-grab;
  background-image: url("data:image/svg+xml,%3Csvg width='21' height='19' viewBox='0 0 21 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.8438 1.9375L19.3438 9.4375L11.8438 16.9375' stroke='%23FF0000' stroke-width='2.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M19.3437 9.4375L1.40625 9.4375' stroke='%23FF0000' stroke-width='2.5' stroke-linecap='round'/%3E%3C/svg%3E%0A");
  background-position: 46% 50%;
  background-repeat: no-repeat;
  background-size: 17px 17px;
  pointer-events: auto;
}
.slide-confirm-slider .pullee::-ms-thumb {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #fff;
  transform-origin: 50% 50%;
  transform: scale(1);
  transition: transform ease-out 100ms;
  cursor: grab;
  cursor: -webkit-grab;
  background-image: url("data:image/svg+xml,%3Csvg width='21' height='19' viewBox='0 0 21 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.8438 1.9375L19.3438 9.4375L11.8438 16.9375' stroke='%23FF0000' stroke-width='2.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M19.3437 9.4375L1.40625 9.4375' stroke='%23FF0000' stroke-width='2.5' stroke-linecap='round'/%3E%3C/svg%3E%0A");
  background-position: 46% 50%;
  background-repeat: no-repeat;
  background-size: 17px 17px;
  pointer-events: auto;
}
.slide-confirm-slider .pullee.complete::-webkit-slider-thumb {
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.28125 10.5063L6.55313 16.5313L16.3438 1.46875' stroke='%23FF0000' stroke-width='2.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-position: 51% 49%;
  transform: scale(1);
}
.slide-confirm-slider .pullee.complete::-moz-range-thumb {
  border: 0;
  background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.28125 10.5063L6.55313 16.5313L16.3438 1.46875' stroke='%23FF0000' stroke-width='2.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-position: 51% 49%;
  transform: scale(1);
}
.slide-confirm-slider .pullee.complete::-ms-thumb {
  background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.28125 10.5063L6.55313 16.5313L16.3438 1.46875' stroke='%23FF0000' stroke-width='2.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-position: 51% 49%;
  transform: scale(1);
}
.slide-confirm-slider .pullee::-webkit-slider-runnable-track {
  height: 40px;
  padding: 0.25rem;
  box-sizing: content-box;
  border-radius: var(--ui-button-border-radius);
  background-color: #ff0000;
  padding: 2px;
}
.slide-confirm-slider .pullee::-moz-range-track {
  height: 40px;
  padding: 0.25rem;
  box-sizing: content-box;
  border-radius: var(--ui-button-border-radius);
  background-color: #ff0000;
  padding: 2px;
}
.slide-confirm-slider .pullee::-moz-focus-outer {
  border: 0;
}
.slide-confirm-slider .pullee::-ms-track {
  border: 0;
  height: 40px;
  padding: 0.25rem;
  box-sizing: content-box;
  border-radius: var(--ui-button-border-radius);
  background-color: #ff0000;
  color: transparent;
  padding: 2px;
}
.slide-confirm-slider .pullee::-ms-fill-lower, .slide-confirm-slider .pullee::-ms-fill-upper {
  background-color: transparent;
}
.slide-confirm-slider .pullee::-ms-tooltip {
  display: none;
}
.slide-confirm-slider .loader div,
.slide-confirm-slider .loader div svg {
  width: 20px;
  height: 20px;
}
.slide-confirm-slider.success-loading {
  pointer-events: none !important;
}
.slide-confirm-slider.success-loading .pullee::-webkit-slider-thumb {
  appearance: none;
  background-image: none;
  background: transparent;
  pointer-events: none;
}
.slide-confirm-slider.success-loading .pullee::-moz-range-thumb {
  border: 0;
  background-image: none;
  background: transparent;
  pointer-events: none;
}
.slide-confirm-slider.success-loading .pullee::-ms-thumb {
  background-image: none;
  background: transparent;
  pointer-events: none;
}
.slide-confirm-slider.success-loading * {
  pointer-events: none !important;
}
.slide-confirm-slider .loader {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-13px, -50%);
  pointer-events: none;
}
.slide-confirm-slider .loader svg circle {
  fill: transparent;
  stroke: #fff;
}
.slide-confirm-slider .loader svg g {
  fill: #fff;
}