.loader {
  line-height: 0;
  pointer-events: none;
  border-radius: 100%;
}
.loader.padding-window {
  padding: var(--ui-padding-uiWindow);
}
.loader.hidden {
  display: none;
}
.loader > div {
  border-radius: 100%;
  width: 20px;
  height: 20px;
  -webkit-animation: spin-loading 12s ease-out;
  -webkit-animation-iteration-count: infinite;
  -moz-animation: spin-loading 12s ease-out;
  -moz-animation-iteration-count: infinite;
  animation: spin-loading 12s ease-out;
  animation-iteration-count: infinite;
}
.loader > div svg {
  max-width: 100%;
  height: auto;
}
.loader.center {
  position: absolute;
  top: calc(50% - 10px);
  left: 50%;
  transform: translate(-50%, -50%);
}
.loader.flex-center {
  display: inline-flex;
  align-self: center;
  justify-self: center;
}
.loader.dark svg > * {
  opacity: 0.6;
  color: var(--ui-color-flat-reverse);
  fill: var(--ui-color-flat-reverse);
}
.loader.light svg > * {
  opacity: 0.25;
  color: var(--ui-color-flat-reverse);
  fill: var(--ui-color-flat-reverse);
}
.loader.linear > div {
  fill: var(--ui-color-flat);
  transform-origin: center;
  -webkit-animation: linear-spin 1s linear;
  -webkit-animation-iteration-count: infinite;
  -moz-animation: linear-spin 1s linear;
  -moz-animation-iteration-count: infinite;
  animation: linear-spin 1s linear;
  animation-iteration-count: infinite;
}
.loader.fade-in {
  opacity: 0;
  animation-delay: 0.1s;
  -webkit-animation-delay: 0.1s;
  -moz-animation-delay: 0.1s;
  animation-duration: 12s;
  animation-iteration-count: infinite;
  animation: fade-in 0.8s ease-in-out;
  -moz-animation: fade-in 0.8s ease-in-out;
  -webkit-animation: fade-in 0.8s ease-in-out;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.loader.linear > div {
  transform-origin: center;
  -webkit-animation: linear-spin var(--ui-load-spinner-large-speed) linear infinite;
  -moz-animation: linear-spin var(--ui-load-spinner-large-speed) linear infinite;
  animation: linear-spin var(--ui-load-spinner-large-speed) linear infinite;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.loader.linear > div.delay {
  animation-delay: 0.8;
  -webkit-animation-delay: 0.5;
  -moz-animation-delay: 0.5;
}
.loader.reverse > div {
  animation-direction: reverse;
}
.loader *.red > div {
  fill: red !important;
}

@-webkit-keyframes spin-loading {
  0% {
    transform: rotate(0deg);
  }
  9% {
    transform: rotate(1050deg);
  }
  18% {
    transform: rotate(-1090deg);
  }
  20% {
    transform: rotate(-1080deg);
  }
  23% {
    transform: rotate(-1080deg);
  }
  28% {
    transform: rotate(-1095deg);
  }
  29% {
    transform: rotate(-1065deg);
  }
  34% {
    transform: rotate(-1080deg);
  }
  35% {
    transform: rotate(-1050deg);
  }
  40% {
    transform: rotate(-1065deg);
  }
  41% {
    transform: rotate(-1035deg);
  }
  44% {
    transform: rotate(-1035deg);
  }
  47% {
    transform: rotate(-2160deg);
  }
  50% {
    transform: rotate(-2160deg);
  }
  56% {
    transform: rotate(45deg);
  }
  60% {
    transform: rotate(45deg);
  }
  80% {
    transform: rotate(6120deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
/*spinning */
@-webkit-keyframes spin-loading {
  0% {
    transform: rotate(0deg);
  }
  9% {
    transform: rotate(1050deg);
  }
  18% {
    transform: rotate(-1090deg);
  }
  20% {
    transform: rotate(-1080deg);
  }
  23% {
    transform: rotate(-1080deg);
  }
  28% {
    transform: rotate(-1095deg);
  }
  29% {
    transform: rotate(-1065deg);
  }
  34% {
    transform: rotate(-1080deg);
  }
  35% {
    transform: rotate(-1050deg);
  }
  40% {
    transform: rotate(-1065deg);
  }
  41% {
    transform: rotate(-1035deg);
  }
  44% {
    transform: rotate(-1035deg);
  }
  47% {
    transform: rotate(-2160deg);
  }
  50% {
    transform: rotate(-2160deg);
  }
  56% {
    transform: rotate(45deg);
  }
  60% {
    transform: rotate(45deg);
  }
  80% {
    transform: rotate(6120deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
/* linear spin */
@keyframes linear-spin {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(36deg);
  }
  20% {
    transform: rotate(72deg);
  }
  30% {
    transform: rotate(108deg);
  }
  40% {
    transform: rotate(144deg);
  }
  50% {
    transform: rotate(180deg);
  }
  60% {
    transform: rotate(216deg);
  }
  70% {
    transform: rotate(252deg);
  }
  80% {
    transform: rotate(288deg);
  }
  90% {
    transform: rotate(324deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes delay {
  0% {
    opacity: 0;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}